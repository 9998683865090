import axios from 'axios';
import Home from './main-views/Home';
import ViewArticle from './views/ViewArticle';
import ViewCourse from './views/ViewCourse';
import ViewShopItem from './views/ViewShopItem';
import ViewShoppingOrder from './views/ViewShoppingOrder';
import ViewCourseContent from './views/ViewCourseContent';
import TakeCourseQuiz from './views/TakeCourseQuiz';
import Account from './main-views/Account';
import MySubscription from './views/MySubscription';
import BuySubscriptionPackage from './views/BuySubscriptionPackage'
import ViewCertificateProgram from './views/ViewCertificateProgram';
import BookReader from './views/BookReader';
import AudioListener from './views/AudioListener';


export const BASE_API = 'https://mafunzoapi.mauzo.tz/api/';
//export const BASE_API = 'https://mafunzoapi.mauzo.tz/api/';

export const CDN_URL = 'https://mafunzo.mauzo.tz/cdn/'
//export const CDN_URL = 'https://mafunzo.mauzo.tz/cdn/'

export function getMainView(app) {
  if (app.navItem === 'home' || !app.navItem) {
    return <Home />;
  } if (app.navItem === 'account' || !app.navItem) {
    return <Account />;
  }

  return null;
}


export function getViewerView(app) {
  if (
    app.navSubItem === 'article' &&
    app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <ViewArticle id={app.navExtraItem} />;
  } else if (
    app.navSubItem === 'course' &&
    app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <ViewCourse id={app.navExtraItem} />;
  } else if (
    app.navSubItem === 'certificate-program' &&
    app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <ViewCertificateProgram id={app.navExtraItem} />;
  } else if (
    app.navSubItem === 'shop-item' &&
    app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <ViewShopItem id={app.navExtraItem} />;
  } else if (
    app.navSubItem === 'shopping-order' &&
    app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <ViewShoppingOrder id={app.navExtraItem} />;
  } else if (
    app.navSubItem === 'course-content' &&
    app.navExtraItem &&
    app.navMoreItem
  ) {
    return <ViewCourseContent courseId={app.navExtraItem} contentId={app.navMoreItem} />;
  } else if (
    app.navSubItem === 'take-course-quiz' &&
    app.navExtraItem &&
    app.navMoreItem
  ) {
    return <TakeCourseQuiz courseId={app.navExtraItem} contentId={app.navMoreItem} />;
  } else if (
    app.navSubItem === 'my-subscription' &&
    !app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <MySubscription />;
  } else if (
    app.navSubItem === 'book-reader' &&
    app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <BookReader bookUrl={app.navExtraItem} />;
  } else if (
    app.navSubItem === 'audio-listener' &&
    app.navExtraItem &&
    app.navMoreItem
  ) {
    return <AudioListener audioTitle={app.navMoreItem} audioUrl={app.navExtraItem} />;
  }

  return null;
}

export function replaceSlashesWithSpaces(text) {
  return text.replace(/\//g, ' ');
}


export function getSideViewerView(app) {
  if (
    app.navSubItem === '' &&
    app.navExtraItem &&
    !app.navMoreItem
  ) {
    return "";
  }

  return null;
}

export function getBottomViewerView(app) {

  if (
    app.navSubItem === 'buy-subscription-package' &&
    app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <BuySubscriptionPackage id={app.navExtraItem} />;
  }

  return null;
}

export function stringToArray(string) {
  const array = string.split(",").map(item => item.trim());
  return array;
}

export function getInlineLoader() {
  return (
    <div className="Loader"></div>
  )
}

export function removeSlashes(inputString) {
  return inputString.replace(/\//g, '|');
}

export function restoreSlashes(inputString) {
  return inputString.replace(/\|/g, '/');
}


export function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    // Generate a random index between 0 and i
    const j = Math.floor(Math.random() * (i + 1));

    // Swap the elements at index i and j
    [array[i], array[j]] = [array[j], array[i]];
  }

  return array;
}

export function remove255(input) {
  if (input && input.startsWith('255')) {
    return '0' + input.slice(3);
  }
  return input;
}

export function formatDate(timestamp, fromPhp = true) {
  timestamp = (fromPhp) ? Number(timestamp) * 1000 : timestamp;
  let date = new Date(timestamp);
  return (date.toLocaleString());
}

export function callApi(url, params) {
  return new Promise(async resolve => {
    await getLocalUser().then(user => {
      //..
      //call main api
      let formData = new FormData();

      //append lnguage
      formData.append('language', getCookie('language'));

      for (let key in params) {
        formData.append(key, params[key]);
      }

      for (let key in user) {
        formData.append(key, user[key]);
      }

      axios.post(BASE_API + url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {

        console.log(url, response.data);

        resolve(response.data);
      }).catch(error => {
        resolve({
          status: 0,
          msg: "Network Connection Error",
        })
      })
      //..
    })

  })
}

export function getLocalUser() {
  return new Promise(async resolve => {
    //use cookies
    let userId = getCookie('userId');
    let userToken = getCookie('userToken');
    let userType = getCookie('userType');

    resolve({
      userId,
      userToken,
      userType,
    })


  })
}

export function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  let expires = "expires=" + d.toUTCString();

  // Get the current domain from window.location.hostname
  let domain = window.location.hostname;

  // Set domainPart only if it's not 'localhost'
  let domainPart = domain !== 'localhost' ? `;domain=${domain}` : '';

  // Add the SameSite attribute to restrict cross-origin access (Strict or Lax as per your needs)
  let sameSite = ";SameSite=Strict";

  // Add the Secure flag for HTTPS connections
  let secureFlag = window.location.protocol === 'https:' ? ";Secure" : '';

  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/" + domainPart + sameSite + secureFlag;
}


export function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function formatMoney(x) {
  //round x to 2 decimal places before og function
  x = Number(x);
  x = x.toFixed(0);

  //then continue
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}

export function formatMoneyShorten(num, digits = 2) {
  num = Number.parseFloat(num);
  if (num <= 1 && num >= -1) {
    return num;
  }

  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "K" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "B" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup.slice().reverse().find(function (item) {
    return Math.abs(num) >= item.value; // Use absolute value of num
  });
  return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
}
