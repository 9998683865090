import { useContext, useEffect, useState, useRef } from "react"
import { AppContext } from "../App"
import { PiBuildingOfficeBold, PiStudent } from "react-icons/pi";
import { callApi, CDN_URL, formatMoney, formatMoneyShorten, remove255 } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { getInlineLoader } from "../Helpers";
import SubscriptionExpiredWidget from '../ones/SubscriptionExpiredWidget';
import ImageSlider from "../ones/ImageSlider";
import { STR_BUY_NOW, STR_ENTER_PHONE_NUMBER_FOR_PAYMENT, STR_FINALIZE_PAYMENT_CARD, STR_FINALIZE_PAYMENT_MOBILE, STR_INVALID_PAYMENT_METHOD, STR_INVALID_QUANTITY, STR_INVALID_SHIPPING_ADDRESS, STR_ORDER_NOTES, STR_ORDER_NOW, STR_ORDER_WAS_RECEIVED_WAIT_FOR_FEEDBACK, STR_PAY_WITH_CARD, STR_PAY_WITH_MOBILE, STR_PAYMENT_METHOD, STR_PHONE_NUMBER, STR_PRICE, STR_QUANTITY, STR_SHIPPING_ADDRESS } from "../Strings";
import MobileInterface from "../MobileInterface";
import { MdCheckCircle } from "react-icons/md";
import Login from "./Login";


export default function View({ id }) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ready, setReady] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [images, setImages] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [totalPrice, setTotalPrice] = useState(getTotalPrice());
  const [totalShippingCost, setTotalShippingCost] = useState(getTotalShippingCost());
  const [totalTransactionFees, setTotalTransactionFees] = useState(getTotalTransactionFees());
  const [overallCost, setOverallCost] = useState(getOverallCost());
  const [payWith, setPayWith] = useState("mobile_money");
  const [payPhone, setPayPhone] = useState(remove255(appContext.currentUserData?.phone));
  const [opSuccessful, setOpSuccessful] = useState(false);
  const [shippingAddress, setShippingAddress] = useState("");
  const [notes, setNotes] = useState("");

  function getTotalPrice() {
    let _result = 0;
    if (data) {
      _result = Number(quantity) * Number(data.unitPrice);
    }

    return _result;
  }

  function getTotalShippingCost() {
    let _result = 0;
    return _result;
  }

  function getTotalTransactionFees() {
    let _result = 0;


    return _result;
  }

  function getOverallCost() {
    let _result = 0;
    _result = Number(totalPrice) + Number(totalShippingCost) + Number(totalTransactionFees);


    return _result;
  }

  async function init() {
    //run initializations here
    setReady(false);
    await getData();
    setReady(true);
  }

  function getImages() {
    if (data) {
      const _images = [];

      if (data.img1) {
        _images.push(CDN_URL + data.img1)
      }

      if (data.img2) {
        _images.push(CDN_URL + data.img2)
      }

      if (data.img3) {
        _images.push(CDN_URL + data.img3)
      }


      setImages(_images);
    }
  }

  async function getData() {
    await callApi("get-shop-item-data", { id }).then(response => {
      if (response.status === 1) {
        setData(response.data);
      }
    })
  }

  async function buy() {
    if (!loading) {
      if (Number(quantity) > 0) {
        if ((payWith === 'mobile_money' && payPhone.trim().length > 0) || payWith === 'credit_card') {
          setLoading(true);
          await callApi("create-shopping-order", { itemId: data.id, quantity, payWith, payPhone, shippingAddress, notes }).then(response => {
            if (response.status === 1) {
              setOpSuccessful(true)
              if (payWith === 'credit_card') {
                MobileInterface.openLink(response.payment_gateway_url);
              }
            } else {
              appContext.tellError(response.msg);
            }
          })
          setLoading(false);
        } else {
          appContext.tellError(STR_INVALID_PAYMENT_METHOD[appContext.language]);
        }
      } else {
        appContext.tellError(STR_INVALID_QUANTITY[appContext.language]);
      }
    }
  }

  useEffect(() => {
    init();
  }, [id])

  useEffect(() => {
    getImages();
  }, [data])

  useEffect(() => {
    setTotalPrice(getTotalPrice());
    setTotalShippingCost(getTotalShippingCost());
    setTotalTransactionFees(getTotalTransactionFees());
    setOverallCost(getOverallCost());
  }, [quantity, totalPrice, totalShippingCost, totalTransactionFees, data])

  useEffect(() => {
    appContext.setShowOverlayLoader(loading);
  }, [loading])

  if (ready && data) {
    return (
      <div className="container">

        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6 col-12">
                    <ImageSlider
                      images={images}
                    />
                  </div>

                  <div className="col-md-6 col-12">
                    <h6
                      className="mSectionTitle"
                      style={{
                        paddingBottom: "20px",
                      }}
                    >

                      {data.title}
                      <span
                        className="font-bold text-secondary"
                        style={{
                          fontSize: "14px",
                          display: "block",
                          marginTop: "30px",
                        }}
                      >
                        <span className="font-bold" style={{ fontSize: "32px" }}>{formatMoney(data.unitPrice)}</span> TZS{(data.unit) ? <>/{data.unit}</> : ""}
                      </span>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6 mt-4 mb-4" style={{}}>
            <div
              className="mb-4 font-light"
              style={{
                fontSize: "18px",
                borderLeft: "6px solid var(--secondaryColor)",
                paddingLeft: "10px",
              }}
            >
              <pre>
                {data.description}
              </pre>
            </div>
          </div>

          <div className="col-md-6">
            <div
              style={{
                maxWidth: "400px",
                margin: "20px auto",
              }}
            >
              <div className="card">
                <div className="card-body">
                  {
                    (appContext.auth && appContext.currentUserData) ?
                      <>
                        {
                          (opSuccessful) ?
                            <div className="mt-4 mb-4 text-center" style={{ width: "100%", padding: "40px" }}>

                              <h1
                                style={{
                                  fontSize: "20px",
                                }}
                              >
                                <MdCheckCircle size={70} className="text-secondary" /> <br />
                                {(payWith === 'mobile_money') ? STR_FINALIZE_PAYMENT_MOBILE[appContext.language] : STR_FINALIZE_PAYMENT_CARD[appContext.language]}
                              </h1>
                            </div>
                            :
                            <>
                              <div className="row">
                                <div className="col-12 mb-2 mt-2">
                                  <label className="form-label">{STR_QUANTITY[appContext.language]}</label>
                                  <input
                                    className="form-control"
                                    value={quantity}
                                    onChange={(e) => {
                                      if (data.itemType === 'digital') {
                                        setQuantity(1)
                                      } else {
                                        if (Number(e.target.value) >= 0) {
                                          setQuantity(e.target.value)
                                        }
                                      }
                                    }}
                                    style={{
                                      border: "1px solid var(--secondaryColor)",
                                    }}
                                    type="number"
                                  />
                                  <div className="form-text">
                                    {data.unit}
                                  </div>
                                </div>

                                <div className="col-12 mb-2 mt-2">
                                  <label className="form-label">{STR_PRICE[appContext.language]} (TZS)</label>
                                  <h6
                                    style={{
                                      fontSize: "32px",
                                    }}
                                  >
                                    {formatMoney(overallCost)}
                                  </h6>
                                </div>

                                <div className="col-12">

                                  {
                                    (data.itemType === 'physical' || data.itemType === 'digital_physical') ?
                                      <>
                                        <div className="mt-2 mb-2">
                                          <label className="form-label">{STR_SHIPPING_ADDRESS[appContext.language]}</label>
                                          <textarea
                                            className="form-control"
                                            value={shippingAddress}
                                            onChange={(e) => setShippingAddress(e.target.value)}
                                            style={{
                                              border: "1px solid var(--secondaryColor)",
                                            }}
                                          >
                                          </textarea>
                                        </div>
                                        <div className="mt-2 mb-2">
                                          <label className="form-label">{STR_ORDER_NOTES[appContext.language]}</label>
                                          <textarea
                                            className="form-control"
                                            value={notes}
                                            onChange={(e) => setNotes(e.target.value)}
                                            style={{
                                              border: "1px solid var(--secondaryColor)",
                                            }}
                                          >
                                          </textarea>
                                        </div>
                                      </> : ""
                                  }

                                  <div className="mt-2 mb-2">
                                    <label className="form-label">{STR_PAYMENT_METHOD[appContext.language]}</label>
                                    <select
                                      className="form-control"
                                      value={payWith}
                                      onChange={(e) => setPayWith(e.target.value)}
                                      style={{
                                        border: "1px solid var(--secondaryColor)",
                                      }}
                                    >
                                      <option value="credit_card">{STR_PAY_WITH_CARD[appContext.language]}</option>
                                      <option value="mobile_money">{STR_PAY_WITH_MOBILE[appContext.language]}</option>
                                    </select>
                                  </div>

                                  {
                                    (payWith === 'mobile_money') ?
                                      <div className="mt-2 mb-2">
                                        <label className="form-label">{STR_PHONE_NUMBER[appContext.language]}</label>
                                        <input
                                          className="form-control"
                                          value={payPhone}
                                          onChange={(e) => setPayPhone(e.target.value)}
                                          style={{
                                            border: "1px solid var(--secondaryColor)",
                                          }}
                                        />
                                        <div className="form-text">{STR_ENTER_PHONE_NUMBER_FOR_PAYMENT[appContext.language]}</div>
                                      </div>
                                      : ""
                                  }


                                </div>

                                <div className="col-12 mb-4 mt-4 text-center">
                                  <button
                                    className="btn btn-padded btn-rounded btn-primary btn-block"
                                    onClick={buy}
                                  >
                                    {STR_BUY_NOW[appContext.language]}
                                  </button>
                                </div>

                              </div>
                            </>
                        }
                      </>
                      :
                      <Login formOnly={true} />
                  }



                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    )
  } else {
    return (
      <div
        className="mSupportLoading container"
      >
        {getInlineLoader()}
      </div>
    );
  }

}