import { useContext, useEffect, useState, useRef } from "react"
import { AppContext } from "../App"
import { PiBuildingOfficeBold, PiStudent } from "react-icons/pi";
import { callApi, CDN_URL, formatMoney, formatMoneyShorten } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { getInlineLoader } from "../Helpers";
import SubscriptionExpiredWidget from '../ones/SubscriptionExpiredWidget';
import TakeQuizesToBeCertified from '../ones/TakeQuizesToBeCertified';
import CourseContentsList from '../views/CourseContentsList';
import { MdPerson } from "react-icons/md";
import { STR_COURSES_IN_THIS_PROGRAM } from "../Strings";
import OneCourse from '../ones/OneCourse';
import Login from "./Login";



export default function View({ id }) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ready, setReady] = useState(false);
  const [data, setData] = useState(null);


  async function init() {
    //run initializations here
    setReady(false);
    await getData();
    setReady(true);
  }

  async function getData() {
    await callApi("get-certificate-program-data", { id }).then(response => {
      if (response.status === 1) {
        setData(response.data);
      }
    })
  }

  useEffect(() => {
    init();
  }, [id])


  if (ready && data) {
    return (
      <div className="container">
        <div className="row">

          {
            (data.cover) ?
              <div className="col-md-6">
                <img
                  style={{
                    width: "100%",
                    height: "auto",
                  }}
                  className="bg-surface"
                  src={CDN_URL + data.cover}
                  alt="Cover Image"
                />
              </div>
              : ""
          }

          <div className={data.cover ? "col-md-6" : "col-md-12"}>
            <h6
              className="mSectionTitle"
              style={{
                borderBottom: "1px solid var(--primaryColor)",
                paddingBottom: "20px",
              }}
            >
              {data.title}

            </h6>



          </div>

          <div className="col-md-12">

            <div
              className="mb-4 font-light"
              style={{
                fontSize: "18px",
                borderLeft: "6px solid var(--secondaryColor)",
                paddingLeft: "10px",
              }}
            >
              <pre>
                {data.description}
              </pre>
            </div>

          </div>


          <div
            className="mt-4 mb-4 col-md-12"
          >
            <h6
              style={{
                fontSize: "16px",
              }}
              className="mb-4 font-bold text-secondary"
            >
              {STR_COURSES_IN_THIS_PROGRAM[appContext.language]}
            </h6>

            {
              (data.courses && data.courses.length > 0) ?
                <div className="row">
                  {
                    data.courses.map((item, i) => {
                      return (
                        <div
                          className="col-md-4"
                          key={i}
                        >
                          <OneCourse data={item} size="small" />
                        </div>
                      )
                    })
                  }
                </div>
                :
                <h6
                  style={{
                    fontSize: "14px",
                  }}
                >
                  No courses were found.
                </h6>
            }
          </div>


        </div>
      </div>
    )
  } else {
    return (
      <div
        className="mSupportLoading container"
      >
        {getInlineLoader()}
      </div>
    );
  }




}