import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../App";
import { CDN_URL } from "../Helpers";
import { STR_BUY_NOW, STR_PURCHASE_SUBSCRIPTION_PACKAGE, STR_REQUIRED_AVERAGE_PASSMARK, STR_SUBSCRIPTION_HAS_EXPIRED, STR_TAKE_QUIZES_TO_BE_CERTIFIED } from "../Strings";

export default function View({ courseId, passmark }) {
  const appContext = useContext(AppContext);

  /* Place states here */


  function view() {
    appContext.navTo({
      item: "view",
      subItem: "my-subscription",
    });
  }

  return (
    <div
      className="card mt-4 mShadow3 bg-secondary"
    >
      <div className="card-body text-center">
        <h6
          style={{
            fontSize: "18px",
          }}
          className="font-semi-bold mb-4"
        >
          {STR_TAKE_QUIZES_TO_BE_CERTIFIED[appContext.language]}
        </h6>

        <h6
          style={{
            fontSize: "48px",
          }}
          className=""
        >
          {passmark}%
          <br />
          <span className="badge bg-light" style={{ fontSize: "12px" }}>{STR_REQUIRED_AVERAGE_PASSMARK[appContext.language]}</span>
        </h6>

      </div>
    </div>
  );
}
