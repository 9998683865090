import { useContext, useEffect, useState, useRef } from "react"
import { AppContext } from "../App"
import { AiOutlineUser } from 'react-icons/ai';
import { getInlineLoader } from "../Helpers";
import {
  STR_CHANGE_PHONE_NUMBER,
  STR_CONSULTATION_FEE_PAYMENTS,
  STR_COURSE_CERTIFICATES,
  STR_MY_ORDERS,
  STR_MY_PURCHASES,
  STR_NO_RECORDS,
  STR_PAYMENT_SETTINGS,
  STR_PERSONAL_SETTINGS,
  STR_PURCHASED_BOOK,
  STR_SUBSCRIPTION_FEE_PAYMENTS,
  STR_UPDATE_PERSONAL_INFO,
  STR_UPDATE_USERNAME
} from "../Strings";
import { BsFillPersonLinesFill, BsPersonGear } from 'react-icons/bs';
import { MdInfoOutline, MdList, MdOutlinePayment, MdOutlinePayments, MdOutlinePhonelinkSetup, MdShoppingCart } from 'react-icons/md';
import OneCourseCertificate from '../ones/OneCourseCertificate';
import FullnameUpdater from "../ones/FullnameUpdater";

export default function View(props) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ready, setReady] = useState(false);

  async function init() {
    //run initializations here
    setReady(false);
    await appContext.getCurrentUserData();
    setReady(true);
  }



  useEffect(() => {
    init();
  }, [])


  if (ready) {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          overflow: "scroll",
        }}
        className="mNoScrollBar"
      >
        <div className="container">

          <h1
            className="mSectionTitle"
          >
            {STR_UPDATE_PERSONAL_INFO[appContext.language]}
          </h1>

          <div className="row">
            <div className="col-md-6">
              <FullnameUpdater />
            </div>
          </div>

        </div>


      </div>
    )
  } else {
    return (
      <div
        className="mSupportLoading"
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        {getInlineLoader()}
      </div>
    );
  }


}