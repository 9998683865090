import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { formatDate, formatMoney } from "../Helpers";
import { STR__ORDER_STATUS_INQUIRY, STR_OPEN, STR_ORDER_STATUS_CANCELLED, STR_ORDER_STATUS_DELIVERED, STR_ORDER_STATUS_PENDING, STR_ORDER_STATUS_SUCCESS } from "../Strings";

export default function View({ data }) {

  const appContext = useContext(AppContext);

  /* Place states here */

  function view() {
    if (data.navable) {
      appContext.navTo({
        item: data.navItem,
        subItem: data.navSubItem,
        extraItem: data.navExtraItem,
        moreItem: data.navMoreItem,
      })
    }
  }

  return (
    <div className="One">
      <div className="row">
        <div className="col-12 col-md-12 mb-4">
          <h6
            style={{
              fontSize: "16px"
            }}
            className="font-regular"
          >
            {data.message[appContext.language]}

          </h6>
        </div>

        <div className="col-12 text-end">
          <h6
            style={{
              fontSize: "12px"
            }}
            className="font-light text-start"
          >

            {formatDate(data.timestamp)}

          </h6>

          {
            (data.navable) ?
              <button
                className="btn btn-dark btn-sm"
                onClick={view}
              >
                {STR_OPEN[appContext.language]}
              </button>
              : ""
          }
        </div>

      </div>
    </div>
  )

}