import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../App";
import { callApi, CDN_URL } from "../Helpers";
import { STR_BUY_NOW, STR_PURCHASE_SUBSCRIPTION_PACKAGE, STR_REQUIRED_AVERAGE_PASSMARK, STR_RETAKE_COURSE, STR_SUBSCRIPTION_HAS_EXPIRED, STR_TAKE_QUIZES_TO_BE_CERTIFIED, STR_TOOK_COURSE_BUT_FAILED_TEXT } from "../Strings";
import { TiWarning, TiWarningOutline } from "react-icons/ti";

export default function View({ courseId, callback }) {
  const appContext = useContext(AppContext);

  /* Place states here */
  const [loading, setLoading] = useState(false);

  function view() {
    appContext.navTo({
      item: "view",
      subItem: "my-subscription",
    });
  }

  async function retakeCourse() {
    setLoading(true);
    await callApi("retake-course", { courseId }).then(response => {
      if (response.status === 1) {
        if (callback) {
          callback();
        }
      } else {
        appContext.tellError(response.msg);
      }
    })
    setLoading(false);
  }

  useEffect(() => {
    appContext.setShowOverlayLoader(loading);
  }, [loading])

  return (
    <div
      className="card mt-4 mShadow3 bg-surface"
    >
      <div className="card-body text-center">
        <h6
          style={{
            fontSize: "14px",
          }}
          className="font-semi-bold mb-4"
        >
          <TiWarningOutline size={80} className="text-danger" /><br />
          {STR_TOOK_COURSE_BUT_FAILED_TEXT[appContext.language]}
        </h6>

        <button
          className="btn btn-danger btn-padded btn-rounded mt-4"
          onClick={() => {
            appContext.activateDialog({
              message: STR_RETAKE_COURSE[appContext.language] + "?",
              onConfirm: retakeCourse,
            })
          }}
        >
          {STR_RETAKE_COURSE[appContext.language]}
        </button>

      </div>
    </div>
  );
}
