import { useContext, useEffect, useState, useRef } from "react"
import { AppContext } from "../App"
import { PiBuildingOfficeBold, PiStudent } from "react-icons/pi";
import { formatMoney, formatMoneyShorten } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { getInlineLoader } from "../Helpers";
import TabbedView from "../components/TabbedView";
import {
  STR_LATEST_ARTICLES,
  STR_MENU_ARTICLES,
  STR_MENU_STORIES,
  STR_MENU_UTILITIES,
  STR_MORE_ARTICLES,
  STR_NO_RESULTS,
  STR_OR_SELECT_CATEGORY,
  STR_SEARCH,
  STR_SEARCH_ARTICLES,
  STR_SEARCH_HERE
} from "../Strings";
import OneArticle from '../ones/OneArticle';
import { MdSearch } from "react-icons/md";

export default function View(props) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ready, setReady] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [filter, setFilter] = useState("");
  const [category, setCategory] = useState("");

  async function init() {
    //run initializations here
    setReady(false);
    await appContext.getArticlesFeed();
    setReady(true);
  }

  function processFeed() {
    if (appContext.articlesFeed && appContext.articlesFeed.length > 0) {
      let _feed = [];

      if (filter && filter.trim().length > 0) {
        _feed = appContext.articlesFeed.filter((item, i) => {
          return (String(item.title).toLowerCase().indexOf(String(filter).toLowerCase()) > -1)
        })
      }

      if (category && category.trim().length > 0) {
        _feed = appContext.articlesFeed.filter((item, i) => {
          return (JSON.parse(item.categories).indexOf(category) > -1)
        })
      }

      setSearchResults(_feed);
    }
  }

  useEffect(() => {
    init();
  }, [])

  useEffect(() => {
    processFeed();
  }, [appContext.articlesFeed, category, filter])

  if (ready) {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          overflow: "auto",
          paddingBottom: "40px",
        }}
        className="mNoScrollBar"
      >
        <div className="container">

          <div className="row">

            <div className="col-md-12">
              <h1
                className="mSectionTitle"
              >
                {STR_SEARCH_ARTICLES[appContext.language]}
              </h1>
            </div>

            <div className="col-md-12 mb-4">
              <input
                className="form-control d-block mb-4"
                style={{
                  width: "300px",
                }}
                onChange={(e) => {
                  setFilter(e.target.value);
                  setCategory("");
                }}
                placeholder={STR_SEARCH_HERE[appContext.language]}
              />

              <div className="form-text">{STR_OR_SELECT_CATEGORY[appContext.language]}</div>
              <div className="text-start mb-4">
                {
                  JSON.parse(appContext.systemParams.contentCategories).map((item, i) => {
                    return (
                      <button
                        className={(category === item.id) ? "btn btn-dark btn-sm m-1 btn-rounded" : "btn btn-outline-dark btn-sm m-1 btn-rounded"}
                        onClick={() => {
                          setCategory(item.id);
                          setFilter("");
                        }}
                        key={i}
                      >
                        {item.description[appContext.language]}
                      </button>
                    )
                  })
                }
              </div>
            </div>



            {
              (searchResults && searchResults.length > 0) ?
                searchResults.map((item, i) => {
                  return (
                    <div
                      className="col-md-4"
                      key={i}
                    >
                      <OneArticle data={item} size="small" />
                    </div>
                  )
                })
                :
                <h6
                  style={{
                    fontSize: "14px",
                  }}
                >
                  {STR_NO_RESULTS[appContext.language]}
                </h6>
            }



          </div>

        </div>


      </div>
    )
  } else {
    return (
      <div
        className="mSupportLoading"
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        {getInlineLoader()}
      </div>
    );
  }


}