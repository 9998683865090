import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { formatDate, formatMoney } from "../Helpers";
import { STR__ORDER_STATUS_INQUIRY, STR_ORDER_STATUS_CANCELLED, STR_ORDER_STATUS_DELIVERED, STR_ORDER_STATUS_PENDING, STR_ORDER_STATUS_SUCCESS } from "../Strings";

export default function View({ data }) {

  const appContext = useContext(AppContext);

  /* Place states here */

  function view() {
    appContext.navTo({
      item: 'view',
      subItem: 'shopping-order',
      extraItem: data.id,
    })
  }

  return (
    <div className="One" onClick={view}>
      <div className="row">
        <div className="col-12 col-md-12 mb-4">
          <h6
            style={{
              fontSize: "22px"
            }}
            className="font-semi-bold"
          >
            {data.itemTitle}
            <br />

            {(data.status === 'success') ? <span className="badge bg-info" style={{ fontSize: "12px" }}>{STR_ORDER_STATUS_SUCCESS[appContext.language]}</span> : ""}
            {(data.status === 'inquiry') ? <span className="badge bg-dark" style={{ fontSize: "12px" }}>{STR__ORDER_STATUS_INQUIRY[appContext.language]}</span> : ""}
            {(data.status === 'pending') ? <span className="badge bg-warning" style={{ fontSize: "12px" }}>{STR_ORDER_STATUS_PENDING[appContext.language]}</span> : ""}
            {(data.status === 'delivered') ? <span className="badge bg-success" style={{ fontSize: "12px" }}>{STR_ORDER_STATUS_DELIVERED[appContext.language]}</span> : ""}
            {(data.status === 'cancelled') ? <span className="badge bg-danger" style={{ fontSize: "12px" }}>{STR_ORDER_STATUS_CANCELLED[appContext.language]}</span> : ""}

          </h6>
        </div>

        <div className="col-4 col-md-4">
          <h6
            style={{
              fontSize: "30px"
            }}
            className="font-light"
          >
            {data.quantity}

            <span
              style={{
                display: "block",
                fontSize: "12px"
              }}
              className="font-regular"
            >
              {data.unit}
            </span>
          </h6>
        </div>

        <div className="col-8 col-md-8">
          <h6
            style={{
              fontSize: "30px"
            }}
            className="font-light"
          >
            {formatMoney(data.overallCost)}

            <span
              style={{
                display: "block",
                fontSize: "12px"
              }}
              className="font-regular"
            >
              TZS
            </span>
          </h6>
        </div>

        <div className="col-12 mt-4">
          <h6
            style={{
              fontSize: "12px"
            }}
            className="font-light"
          >

            {formatDate(data.orderDate)}

          </h6>
        </div>

      </div>
    </div>
  )

}