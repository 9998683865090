import { useContext, useEffect, useState, useRef } from "react"
import { AppContext } from "../App"
import { AiOutlineUser } from 'react-icons/ai';
import { CDN_URL, getInlineLoader, restoreSlashes } from "../Helpers";
import {
  STR_CHANGE_PHONE_NUMBER,
  STR_CONSULTATION_FEE_PAYMENTS,
  STR_MY_ORDERS,
  STR_MY_PURCHASES,
  STR_NO_RECORDS,
  STR_PAYMENT_SETTINGS,
  STR_PERSONAL_SETTINGS,
  STR_PURCHASED_BOOK,
  STR_SUBSCRIPTION_FEE_PAYMENTS,
  STR_UPDATE_PERSONAL_INFO,
  STR_UPDATE_USERNAME
} from "../Strings";
import { BsFillPersonLinesFill, BsPersonGear } from 'react-icons/bs';
import { MdInfoOutline, MdList, MdOutlinePayment, MdOutlinePayments, MdOutlinePhonelinkSetup, MdShoppingCart } from 'react-icons/md';
import OnePurchasedItem from '../ones/OnePurchasedItem';
import PdfReader from "../ones/PdfReader";
import AudioPlayer from "../ones/AudioPlayer";

export default function View({ audioUrl, audioTitle }) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ready, setReady] = useState(false);
  const [myPurchases, setMyPurchases] = useState(null); //digital purchases

  async function init() {
    //run initializations here
    //..remember to check if user owns this book
    setReady(false);
    await appContext.getCurrentUserData();
    setReady(true);
  }



  useEffect(() => {
    init();
  }, [audioUrl])

  useEffect(() => {
    if (appContext.currentUserData) {
      const _purchases = JSON.parse(appContext.currentUserData.purchasedItems);
      const _filtered = _purchases.filter((item) => {
        return (item.itemType === 'digital')
      })

      setMyPurchases(_filtered)
    }
  }, [appContext.currentUserData])



  if (ready && audioUrl) {
    return (
      <div className="container">
        <AudioPlayer title={audioTitle} url={CDN_URL + restoreSlashes(audioUrl)} />
      </div>
    )
  } else {
    return (
      <div
        className="mSupportLoading"
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        {getInlineLoader()}
      </div>
    );
  }


}