import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../App";
import { CDN_URL } from "../Helpers";

export default function View({ data, size = 'small' }) {
  const appContext = useContext(AppContext);

  /* Place states here */


  function view() {
    appContext.navTo({
      item: "view",
      subItem: "article",
      extraItem: data.id,
    });
  }

  if (size === 'big') {
    return (
      <div
        className="OneArticle big"
        onClick={view}
        style={{
          backgroundImage: `url(${CDN_URL + data.cover})`
        }}
      >

        <div className="cover">
          {
            (data.isPaid === 'yes') ?
              <span
                className="badge bg-primary font-bold"
                style={{
                  fontSize: "12px",
                  marginLeft: "10px",
                  marginTop: "10px",
                }}
              >
                Premium
              </span>
              :
              <span
                className="badge bg-secondary font-bold"
                style={{
                  fontSize: "12px",
                  marginLeft: "10px",
                  marginTop: "10px",
                }}
              >
                Free
              </span>
          }
          <div
            className="title"
          >
            {data.title}
          </div>
        </div>
      </div>
    );
  } else if (size === 'small') {
    return (
      <div
        className="OneArticle small bg-surface"
        onClick={view}
      >

        <div
          className="cover"
          style={{
            backgroundImage: `url(${CDN_URL + data.cover})`
          }}
        >
        </div>

        <div
          className="title"
        >
          {
            (data.isPaid === 'yes') ?
              <span
                className="badge bg-primary font-bold"
                style={{
                  fontSize: "12px",
                }}
              >
                Premium
              </span>
              :
              <span
                className="badge bg-secondary font-bold"
                style={{
                  fontSize: "12px",
                }}
              >
                Free
              </span>
          }
          <br />
          {data.title}
        </div>
      </div>
    );
  }
}
