import { useContext, useEffect, useState, useRef } from "react"
import { AppContext } from "../App"
import { PiBuildingOfficeBold, PiStudent } from "react-icons/pi";
import { formatMoney, formatMoneyShorten } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { getInlineLoader } from "../Helpers";
import TabbedView from "../components/TabbedView";
import {
  STR_BOOKS,
  STR_LATEST_COURSES,
  STR_MORE_COURSES,
  STR_NO_ITEMS_FOUND,
  STR_OTHER_ITEMS,
  STR_SEARCH_HERE,
  STR_SHOP,
} from "../Strings";
import OneShopItem from '../ones/OneShopItem';

export default function View(props) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ready, setReady] = useState(false);
  const [filter, setFilter] = useState("");
  const [books, setBooks] = useState([]);
  const [others, setOthers] = useState([]);

  async function init() {
    //run initializations here
    setReady(false);
    await appContext.getShopFeed();
    setReady(true);
  }

  function groupItems() {
    const _books = [];
    const _others = [];

    if (appContext.shopFeed) {
      for (const _item of appContext.shopFeed) {
        if (_item.itemCategory === 'book') {
          _books.push(_item)
        } else if (_item.itemCategory === 'others') {
          _others.push(_item)
        }
      }
    }

    setBooks(_books);
    setOthers(_others);
  }

  useEffect(() => {
    init();
  }, [])

  useEffect(() => {
    groupItems();
  }, [appContext.shopFeed])

  if (ready) {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          overflow: "scroll",
          paddingBottom: "40px",
        }}
        className="mNoScrollBar"
      >
        <div className="container">

          <div className="row">
            <div className="col-md-12">
              <h1
                className="mSectionTitle"
              >
                {STR_SHOP[appContext.language]}
              </h1>
            </div>

            <div className="col-md-12 mb-4">
              <input
                className="form-control d-block mb-4"
                style={{
                  width: "300px",
                }}
                onChange={(e) => {
                  setFilter(e.target.value);
                }}
                placeholder={STR_SEARCH_HERE[appContext.language]}
              />
            </div>

            <div className="col-md-12 mt-2 mb-2">
              <h6
                style={{
                  fontSize: "24px",
                  borderLeft: "12px solid var(--primaryColor)",
                  paddingLeft: "10px",
                }}
                className="font-semi-bold"
              >
                {STR_BOOKS[appContext.language]}
              </h6>
            </div>
            {
              (books && books.length > 0) ?
                books.map((item, i) => {
                  if (filter && filter.trim().length > 0) {
                    if (String(item.title).toLowerCase().indexOf(String(filter).toLowerCase()) > -1) {
                      return (
                        <div
                          className="col-md-4"
                          key={i}
                        >
                          <OneShopItem data={item} size="small" />
                        </div>
                      )
                    } else {
                      return "";
                    }
                  } else {
                    return (
                      <div
                        className="col-md-4"
                        key={i}
                      >
                        <OneShopItem data={item} size="small" />
                      </div>
                    )
                  }

                })
                :
                <h6
                  style={{
                    fontSize: "14px",
                  }}
                >
                  {STR_NO_ITEMS_FOUND[appContext.language]}
                </h6>
            }


            <div className="col-md-12 mb-2" style={{ marginTop: "60px" }}>
              <h6
                style={{
                  fontSize: "24px",
                  borderLeft: "12px solid var(--primaryColor)",
                  paddingLeft: "10px",
                }}
                className="font-semi-bold"
              >
                {STR_OTHER_ITEMS[appContext.language]}
              </h6>
            </div>
            {
              (others && others.length > 0) ?
                others.map((item, i) => {
                  if (filter && filter.trim().length > 0) {
                    if (String(item.title).toLowerCase().indexOf(String(filter).toLowerCase()) > -1) {
                      return (
                        <div
                          className="col-md-4"
                          key={i}
                        >
                          <OneShopItem data={item} size="small" />
                        </div>
                      )
                    } else {
                      return "";
                    }
                  } else {
                    return (
                      <div
                        className="col-md-4"
                        key={i}
                      >
                        <OneShopItem data={item} size="small" />
                      </div>
                    )
                  }

                })
                :
                <h6
                  style={{
                    fontSize: "14px",
                  }}
                >
                  {STR_NO_ITEMS_FOUND[appContext.language]}
                </h6>
            }


          </div>

        </div>


      </div>
    )
  } else {
    return (
      <div
        className="mSupportLoading"
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        {getInlineLoader()}
      </div>
    );
  }


}