import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import AccountHome from "../views/AccountHome";
import MyShoppingOrders from "../views/MyShoppingOrders";
import MyPurchases from "../views/MyPurchases";
import MyDigitalPurchases from "../views/DigitalPurchases";
import MyPhysicalPurchases from "../views/PhysicalPurchases";
import CourseCertificates from "../views/CourseCertificates";
import ProgramCertificates from "../views/ProgramCertificates";
import UpdatePersonalInfo from '../views/UpdatePersonalInfo';
import Notifications from '../views/Notifications';
import Login from "../views/Login";

export default function Account(props) {

  const appContext = useContext(AppContext);

  const [view, setView] = useState(null);

  async function init() {
    //run initializations here
  }

  function getView() {
    if (appContext.auth && appContext.currentUserData) {
      if (
        !appContext.navSubItem &&
        !appContext.navExtraItem &&
        !appContext.navMoreItem
      ) {
        return <AccountHome />
      } else if (
        appContext.navSubItem === 'my-shopping-orders' &&
        !appContext.navExtraItem &&
        !appContext.navMoreItem
      ) {
        return <MyShoppingOrders />
      } else if (
        appContext.navSubItem === 'my-purchases' &&
        !appContext.navExtraItem &&
        !appContext.navMoreItem
      ) {
        return <MyPurchases />
      } else if (
        appContext.navSubItem === 'my-digital-purchases' &&
        !appContext.navExtraItem &&
        !appContext.navMoreItem
      ) {
        return <MyDigitalPurchases />
      } else if (
        appContext.navSubItem === 'my-physical-purchases' &&
        !appContext.navExtraItem &&
        !appContext.navMoreItem
      ) {
        return <MyPhysicalPurchases />
      } else if (
        appContext.navSubItem === 'course-certificates' &&
        !appContext.navExtraItem &&
        !appContext.navMoreItem
      ) {
        return <CourseCertificates />
      } else if (
        appContext.navSubItem === 'program-certificates' &&
        !appContext.navExtraItem &&
        !appContext.navMoreItem
      ) {
        return <ProgramCertificates />
      } else if (
        appContext.navSubItem === 'update-personal-info' &&
        !appContext.navExtraItem &&
        !appContext.navMoreItem
      ) {
        return <UpdatePersonalInfo />
      } else if (
        appContext.navSubItem === 'notifications' &&
        !appContext.navExtraItem &&
        !appContext.navMoreItem
      ) {
        return <Notifications />
      }
    } else {
      return <Login />
    }


    return null;
  }

  useEffect(() => {
    init();
  }, [])

  useEffect(() => {
    const _view = getView();
    if (_view) {
      setView(_view);
    }
  }, [appContext.navSubItem, appContext.navExtraItem, appContext.navMoreItem])

  //return desired view
  return view;
}