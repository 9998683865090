import { useContext, useEffect, useState, useRef } from "react"
import { AppContext } from "../App"
import { AiOutlineUser } from 'react-icons/ai';
import { getInlineLoader, setCookie } from "../Helpers";
import {
  STR_CHANGE_PHONE_NUMBER,
  STR_CONSULTATION_FEE_PAYMENTS,
  STR_COURSE_CERTIFICATES,
  STR_LOGOUT,
  STR_MY_ORDERS,
  STR_MY_PURCHASES,
  STR_NOTIFICATIONS,
  STR_OTHER_PURCHASES,
  STR_PAYMENT_SETTINGS,
  STR_PERSONAL_SETTINGS,
  STR_PROGRAM_CERTIFICATES,
  STR_PURCHASED_BOOK,
  STR_SUBSCRIPTION_FEE_PAYMENTS,
  STR_UPDATE_PERSONAL_INFO,
  STR_UPDATE_USERNAME
} from "../Strings";
import { BsFillPersonLinesFill, BsPersonGear } from 'react-icons/bs';
import { MdInfoOutline, MdList, MdLogout, MdNotifications, MdOutlineNotifications, MdOutlinePayment, MdOutlinePayments, MdOutlinePhonelinkSetup, MdShoppingCart, MdTranslate } from 'react-icons/md';
import { GrCertificate } from "react-icons/gr";
import { TbNotification } from "react-icons/tb";

export default function View(props) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ready, setReady] = useState(false);

  async function init() {
    //run initializations here
    setReady(false);
    await appContext.getCurrentUserData();
    setReady(true);
  }

  function logOut() {
    appContext.setShowOverlayLoader(true);
    setCookie('userId', "", -10000000);
    setCookie('userToken', "", -10000000);
    appContext.navTo({
      item: '',
    })
    appContext.refresh();
    appContext.setShowOverlayLoader(false);
  }


  useEffect(() => {
    init();
  }, [])



  if (ready) {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          overflow: "scroll",
        }}
        className="mNoScrollBar"
      >
        <div className="container">

          <div className="row">

            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <h1
                  >
                    <AiOutlineUser className="text-primary" size={100} />

                    <span
                      style={{
                        display: "block",
                        fontSize: "18px"
                      }}
                      className="font-semi-bold text-primary"
                    >
                      {(appContext.currentUserData.phone) ? <>+{appContext.currentUserData.phone}</> : ""}
                      {(appContext.currentUserData.email) ? <span style={{ fontSize: "14px" }}>{appContext.currentUserData.email}</span> : ""}
                    </span>
                    <span
                      style={{
                        display: "block",
                        fontSize: "28px"
                      }}
                      className="text-primary"
                    >
                      {appContext.currentUserData.fullname}
                    </span>
                  </h1>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="card">
                <div className="card-body">
                  <h6
                    className="card-title"
                  >
                    {STR_PERSONAL_SETTINGS[appContext.language]}
                  </h6>

                  {/*<div
                    className="d-flex mb-4 mt-4"
                    style={{
                      padding: "0",

                    }}
                  >
                    <BsPersonGear className="align-self-center mNoMargin" size={24} />
                    <h6
                      className="mNoMargin align-self-center font-semi-bold"
                      style={{
                        fontSize: "14px",
                        paddingLeft: "20px",
                      }}
                    >
                      {STR_UPDATE_USERNAME[appContext.language]}
                    </h6>
                  </div>*/}

                  {/*<div
                    className="d-flex mb-4 mt-4"
                    style={{
                      padding: "0",

                    }}
                  >
                    <MdOutlinePhonelinkSetup className="align-self-center mNoMargin" size={24} />
                    <h6
                      className="mNoMargin align-self-center font-semi-bold"
                      style={{
                        fontSize: "14px",
                        paddingLeft: "20px",
                      }}
                    >
                      {STR_CHANGE_PHONE_NUMBER[appContext.language]}
                    </h6>
                  </div>*/}

                  <div
                    className="d-flex mb-4 mt-4"
                    style={{
                      padding: "0",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      appContext.navTo({
                        item: 'account',
                        subItem: 'notifications',
                      })
                    }}
                  >
                    <MdOutlineNotifications className="align-self-center mNoMargin" size={24} />
                    <h6
                      className="mNoMargin align-self-center font-semi-bold"
                      style={{
                        fontSize: "14px",
                        paddingLeft: "20px",
                      }}
                    >
                      {STR_NOTIFICATIONS[appContext.language]}
                    </h6>
                  </div>

                  <div
                    className="d-flex mb-4 mt-4"
                    style={{
                      padding: "0",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      appContext.navTo({
                        item: 'account',
                        subItem: 'update-personal-info',
                      })
                    }}
                  >
                    <MdInfoOutline className="align-self-center mNoMargin" size={24} />
                    <h6
                      className="mNoMargin align-self-center font-semi-bold"
                      style={{
                        fontSize: "14px",
                        paddingLeft: "20px",
                      }}
                    >
                      {STR_UPDATE_PERSONAL_INFO[appContext.language]}
                    </h6>
                  </div>

                  <div
                    className="d-flex mb-4 mt-4"
                    style={{
                      padding: "0",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      appContext.navTo({
                        item: 'account',
                        subItem: 'course-certificates',
                      })
                    }}
                  >
                    <GrCertificate className="align-self-center mNoMargin" size={24} />
                    <h6
                      className="mNoMargin align-self-center font-semi-bold"
                      style={{
                        fontSize: "14px",
                        paddingLeft: "20px",
                      }}
                    >
                      {STR_COURSE_CERTIFICATES[appContext.language]}
                    </h6>
                  </div>

                  <div
                    className="d-flex mb-4 mt-4"
                    style={{
                      padding: "0",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      appContext.navTo({
                        item: 'account',
                        subItem: 'program-certificates',
                      })
                    }}
                  >
                    <GrCertificate className="align-self-center mNoMargin" size={24} />
                    <h6
                      className="mNoMargin align-self-center font-semi-bold"
                      style={{
                        fontSize: "14px",
                        paddingLeft: "20px",
                      }}
                    >
                      {STR_PROGRAM_CERTIFICATES[appContext.language]}
                    </h6>
                  </div>

                  <div
                    className="d-flex mb-4 mt-4"
                    style={{
                      padding: "0",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      appContext.activateDialog({
                        message: STR_LOGOUT[appContext.language] + "?",
                        onConfirm: logOut,
                      })
                    }}
                  >
                    <MdLogout className="align-self-center mNoMargin" size={24} />
                    <h6
                      className="mNoMargin align-self-center font-semi-bold"
                      style={{
                        fontSize: "14px",
                        paddingLeft: "20px",
                      }}
                    >
                      {STR_LOGOUT[appContext.language]}
                    </h6>
                  </div>

                  <div
                    className="d-flex mb-4 mt-4"
                    style={{
                      padding: "0",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      if (appContext.language === 'en') {
                        appContext.setLanguage("sw");
                      } else {
                        appContext.setLanguage("en");
                      }
                    }}
                  >
                    <MdTranslate className="align-self-center mNoMargin" size={24} />
                    <h6
                      className="mNoMargin align-self-center font-semi-bold"
                      style={{
                        fontSize: "14px",
                        paddingLeft: "20px",
                      }}
                    >
                      {(appContext.language === 'en') ? "Tumia Kiswahili" : "Use English"}
                    </h6>
                  </div>

                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="card">
                <div className="card-body">
                  <h6
                    className="card-title"
                  >
                    {STR_PAYMENT_SETTINGS[appContext.language]}
                  </h6>

                  <div
                    className="d-flex mb-4 mt-4"
                    style={{
                      padding: "0",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      appContext.navTo({
                        item: 'view',
                        subItem: 'my-subscription',
                      })
                    }}
                  >
                    <MdOutlinePayment className="align-self-center mNoMargin" size={24} />
                    <h6
                      className="mNoMargin align-self-center font-semi-bold"
                      style={{
                        fontSize: "14px",
                        paddingLeft: "20px",
                      }}
                    >
                      {STR_SUBSCRIPTION_FEE_PAYMENTS[appContext.language]}
                    </h6>
                  </div>

                  <div
                    className="d-flex mb-4 mt-4"
                    style={{
                      padding: "0",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      appContext.navTo({
                        item: 'account',
                        subItem: 'my-shopping-orders',
                      })
                    }}
                  >
                    <MdList className="align-self-center mNoMargin" size={24} />
                    <h6
                      className="mNoMargin align-self-center font-semi-bold"
                      style={{
                        fontSize: "14px",
                        paddingLeft: "20px",
                      }}
                    >
                      {STR_MY_ORDERS[appContext.language]}
                    </h6>
                  </div>

                  <div
                    className="d-flex mb-4 mt-4"
                    style={{
                      padding: "0",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      appContext.navTo({
                        item: 'account',
                        subItem: 'my-digital-purchases',
                      })
                    }}
                  >
                    <MdShoppingCart className="align-self-center mNoMargin" size={24} />
                    <h6
                      className="mNoMargin align-self-center font-semi-bold"
                      style={{
                        fontSize: "14px",
                        paddingLeft: "20px",
                      }}
                    >
                      {STR_PURCHASED_BOOK[appContext.language]}
                    </h6>
                  </div>

                  <div
                    className="d-flex mb-4 mt-4"
                    style={{
                      padding: "0",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      appContext.navTo({
                        item: 'account',
                        subItem: 'my-physical-purchases',
                      })
                    }}
                  >
                    <MdShoppingCart className="align-self-center mNoMargin" size={24} />
                    <h6
                      className="mNoMargin align-self-center font-semi-bold"
                      style={{
                        fontSize: "14px",
                        paddingLeft: "20px",
                      }}
                    >
                      {STR_OTHER_PURCHASES[appContext.language]}
                    </h6>
                  </div>

                </div>
              </div>
            </div>

          </div>

        </div>


      </div>
    )
  } else {
    return (
      <div
        className="mSupportLoading"
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        {getInlineLoader()}
      </div>
    );
  }


}