import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../App";
import { CDN_URL } from "../Helpers";
import { MdOutlineCheckCircle } from "react-icons/md";

export default function View({ data, size = 'small' }) {
  const appContext = useContext(AppContext);

  /* Place states here */
  const [isTaken, setIsTaked] = useState(false);
  const [score, setScore] = useState(0);

  function view() {
    appContext.navTo({
      item: "view",
      subItem: "course",
      extraItem: data.id,
    });
  }

  function init() {
    appContext.getCurrentUserData();
  }

  useEffect(() => {
    init();
  }, [data])

  useEffect(() => {
    if (appContext.currentUserData) {
      const courseCertificatesEarned = JSON.parse(appContext.currentUserData.courseCertificatesEarned);

      for (const course of courseCertificatesEarned) {
        if (Number(course.courseId) === Number(data.id)) {
          setIsTaked(true);
          setScore(course.averageScore);
          break;
        }
      }
    }
  }, [appContext.currentUserData])

  if (size === 'big') {
    return (
      <div
        className="OneArticle big"
        onClick={view}
        style={{
          backgroundImage: `url(${CDN_URL + data.cover})`
        }}
      >

        <div className="cover">
          {
            (data.isPaid === 'yes') ?
              <span
                className="badge bg-primary font-bold"
                style={{
                  fontSize: "12px",
                  marginLeft: "10px",
                  marginTop: "10px",
                }}
              >
                Premium
              </span>
              :
              <span
                className="badge bg-secondary font-bold"
                style={{
                  fontSize: "12px",
                  marginLeft: "10px",
                  marginTop: "10px",
                }}
              >
                Free
              </span>
          }
          <div
            className="title"
          >
            {data.title}
          </div>
        </div>
      </div>
    );
  } else if (size === 'small') {
    return (
      <div
        className="OneArticle small bg-surface"
        onClick={view}
      >

        <div
          className="cover"
          style={{
            backgroundImage: `url(${CDN_URL + data.cover})`,
            padding: "10px",
          }}
        >
          {
            (data.isPaid === 'yes') ?
              <span
                className="badge bg-primary font-bold"
                style={{
                  fontSize: "12px",
                }}
              >
                Premium
              </span>
              :
              <span
                className="badge bg-secondary font-bold"
                style={{
                  fontSize: "12px",
                }}
              >
                Free
              </span>
          }
        </div>

        <div
          className="title"
        >
          {
            (isTaken) ?
              <>
                <span
                  style={{

                  }}
                  className="badge bg-success"
                >
                  <MdOutlineCheckCircle /> {score}%
                </span>
                <br />
              </>
              : ""
          }
          {data.title}
        </div>
      </div>
    );
  }
}
