import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../App";
import { CDN_URL } from "../Helpers";
import { MdOutlineCheck, MdOutlineCheckCircle } from "react-icons/md";

export default function View({ data, courseId }) {
  const appContext = useContext(AppContext);

  /* Place states here */
  const [isTaken, setIsTaked] = useState(false);
  const [score, setScore] = useState(0);

  function view() {
    appContext.navTo({
      item: "view",
      subItem: "course-content",
      extraItem: courseId,
      moreItem: data.id,
    });
  }

  function init() {
    appContext.getCurrentUserData();
  }

  useEffect(() => {
    init();
  }, [data])

  useEffect(() => {
    if (appContext.currentUserData) {
      const courseQuizesTaken = JSON.parse(appContext.currentUserData.courseQuizesTaken);

      for (const _quiz of courseQuizesTaken) {
        if (Number(_quiz.contentId) === Number(data.id)) {
          setIsTaked(true);
          setScore(_quiz.score);
          break;
        }
      }
    }
  }, [appContext.currentUserData])

  return (
    <div
      className="OneArticle small bg-surface"
      onClick={view}
    >

      <div
        className="cover"
        style={{
          backgroundImage: `url(${CDN_URL + data.cover})`
        }}
      >
      </div>

      <div
        className="title"
      >
        {
          (isTaken) ?
            <>
              <span
                style={{

                }}
                className="badge bg-success"
              >
                <MdOutlineCheckCircle /> {score}%
              </span>
              <br />
            </>
            : ""
        }

        {data.title}
      </div>
    </div>
  );
}
